import type { PropsWithChildren } from 'react'

import { Grid } from '@mui/material'

export const ReadingGrid = (props: PropsWithChildren<{ dataCy: string }>) => {
  const { children, dataCy } = props

  return (
    <Grid container data-cy={dataCy}>
      <Grid item xs={12} lg={8}>
        {children}
      </Grid>
    </Grid>
  )
}
