import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'

import { ReadingGrid } from '@/components/ReadingGrid'
import { SECTIONS_MAP } from '@/constants'
import { SectionLayout } from '@/layouts/SectionLayout'
import { useProduct } from '@/providers/p/ProductProvider'
import { useT } from '@/utils/frontend/useT'
import { isNotEmpty } from '@/utils/plain/isEmpty'

import { BulletList } from '../../BulletList'

const { id } = SECTIONS_MAP.description
const dataCy = 'DescriptionAndDetails'

const DescriptionAndDetailsSection = () => {
  const { product } = useProduct()
  const theme = useTheme()

  const { description, properties, applicationNotes, fieldOfApplication, buildingTypes } =
    product

  const { t } = useT({ keyPrefix: 'product' })

  const generateItems = () => {
    const itemsToRender = []

    if (isNotEmpty(properties)) {
      itemsToRender.push({
        title: t('product-properties-title'),
        children: <BulletList items={properties} dataCy={`${dataCy}-productProperties`} />,
      })
    }

    if (isNotEmpty(applicationNotes)) {
      itemsToRender.push({
        title: t('application-notes-title'),
        children: applicationNotes.map((applicationNote, index) => (
          <Typography
            paragraph
            key={applicationNote}
            variant="body2"
            data-cy={`${dataCy}-applicationNote-${index}`}
          >
            {applicationNote}
          </Typography>
        )),
      })
    }

    if (isNotEmpty(fieldOfApplication)) {
      itemsToRender.push({
        title: t('field-of-application-title'),
        children: fieldOfApplication.map((fieldOfApplicationLine, index) => (
          <Typography
            paragraph
            key={fieldOfApplicationLine}
            variant="body2"
            data-cy={`${dataCy}-fieldOfApplication-${index}`}
          >
            {fieldOfApplicationLine}
          </Typography>
        )),
      })
    }

    if (isNotEmpty(buildingTypes)) {
      itemsToRender.push({
        title: t('building-types-title'),
        children: <BulletList items={buildingTypes} dataCy={`${dataCy}-buildingTypes`} />,
      })
    }

    return itemsToRender
  }

  const items = generateItems()

  return (
    <SectionLayout id={id} title={t('description-details-title')} dataCy={dataCy}>
      <Stack spacing={{ xs: 3, lg: 4 }}>
        {isNotEmpty(description) && (
          <ReadingGrid dataCy={`${dataCy}-description-area`}>
            {description.map((descriptionLine, index) => (
              <Typography
                paragraph
                key={descriptionLine}
                variant="body2"
                data-cy={`${dataCy}-description-${index}`}
              >
                {descriptionLine}
              </Typography>
            ))}
          </ReadingGrid>
        )}
        {isNotEmpty(items) && (
          <ReadingGrid dataCy={`${dataCy}-details-area`}>
            {items.map((item) => (
              <Accordion key={item.title}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} id={item.title}>
                  {item.title}
                </AccordionSummary>
                <AccordionDetails sx={{ color: theme.palette.text.secondary }}>
                  {item.children}
                </AccordionDetails>
              </Accordion>
            ))}
          </ReadingGrid>
        )}
      </Stack>
      <div
        id="qualtrics-inline-feedback"
        style={{
          margin: '0px',
        }}
      />
    </SectionLayout>
  )
}

export default DescriptionAndDetailsSection
